
 import * as API from '@/api/index'

 export default {
     // 获取套餐卡列表
     list: params => {
         return API.POST('api/stockPackageCard/list', params)
     },
     //新增套餐卡接口
     create: params => {
        return API.POST('api/stockPackageCard/create', params)
    },
    //编辑套餐卡接口
    update: params => {
        return API.POST('api/stockPackageCard/update', params)
    },
    //查询销售门店
    SaleEntity: params => {
        return API.POST('api/stockPackageCard/packageCardSaleEntity', params)
    },
    //获取产品明细
    packageCardGoods: params => {
        return API.POST('api/stockPackageCard/packageCardGoods', params)
    },
 }
 